import React from "react"

const productDemo = () => {
  // sessionStorage.setItem("isProductDemo", true)
  // window.location = "/"
  if (typeof sessionStorage !== `undefined`) {
    sessionStorage.setItem("isProductDemo", true)
    window.location = "/"
  }

  return <div></div>
}

export default productDemo